// @ts-nocheck
import React, { useEffect, useContext } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { useMediaQuery } from 'react-responsive'
import AccountStepsMobileHeader from './AccountStepsMobileHeader'

import { mediaQueriesBreakpoints, respondAbove } from 'styles/mixins'
import oStorage from '../../utils/o-storage'
import VerifyIdentifier from './Steps/VerifyIdentifier'
import VerifyCode from './Steps/VerifyCode'
import ExtendUserDetails from './Steps/ExtendUserDetails'
import { SIGN_UP_STEPS } from '../../constants/signUpProcess'
import { sendCustomEvent } from '../../utils/analytics/analytics'
import ReactNativeComms from 'utils/reactNativeComms'
import { formatLocalDateTime, getStoreName, sendRequest, getTranslatedTextByKey } from '../../utils/utils'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import { StoreContext } from 'contexts/StoreContext'

const StyledLogo = styled.div`
	height: 35px;
	margin: ${({ fullscreen }) => (fullscreen ? '100px 0 0 0' : '0 auto')};
	width: 120px;

	${respondAbove.md`
		margin: 0 auto;
		height: 45px;
		width: unset;
	`}

	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-image: ${({ $logoUrl }) => `url('${$logoUrl}')`};
	flex-grow: 1;
`

const StyledCloseIcon = styled(CloseIcon)`
	color: var(--disable);
`

const StyledModal = styled(Modal)`
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
`

const StyledTopGrid = styled(Grid)`
	text-align: end;
	height: 59px;
`

const StyledBottomGrid = styled(Grid)`
	padding: 0 20px;

	${respondAbove.md`
		padding: 0 120px;
	`}
`

const StyledPaper = styled(Paper)`
	width: 95vw;
	height: 352px;

	${respondAbove.md`
		width: 648px;
		height: ${({ $reCaptchaEnabled, $friendlyCaptchaEnabled }) => {
			if ($reCaptchaEnabled) {
				return '710px'
			}
			if ($friendlyCaptchaEnabled) {
				return '660px'
			}
			return '600px'
		}};
	`}

	border: none;
	border-radius: 4px;
	background-color: #ffffff;
	text-align: center;
	outline: none;
	position: relative;
`

const StyledButton = styled(IconButton)`
	padding: 10px;
	margin: 10px;
`

const StyledGridRoot = styled(Grid)`
	height: 100%;
`

const SignUpContainer = styled.div`
	height: 100%;
	position: fixed;
	background: #fff;
	z-index: 9999999;
	top: 0;
	width: 100%;
	overflow-y: auto;
`

const AccountStepsManager = inject(
	'Infra',
	'Account',
	'User',
	'MobileApplication',
	'Home',
	'AddressManager',
	'Application'
)(
	observer(({ Application, Account, Infra, User, MobileApplication, Home, AddressManager }) => {
		const {
			signUpStep,
			openSignUpPopUp,
			signUpPopUp,
			setPrivacyPolicy,
			setTermsConditions,
			skip,
			login,
			getUserTokens,
			checkPushNotificationsStatus,
		} = Account
		const isMobileView = !useMediaQuery({ query: mediaQueriesBreakpoints.lg })
		const { store } = useContext(StoreContext)
		const reCaptchaEnabled = Infra.appParams.features.reCaptcha?.enabled
		const friendlyCaptchaEnabled = Infra.appParams.features.friendlyCaptcha?.enabled

		let logoUrl

		if (Infra?.appParams?.eCommerce) {
			if (isMobileView) {
				logoUrl = Infra?.appParams?.assets?.logoMobileHeader
			} else {
				logoUrl = Infra?.appParams?.assets?.logoDesktopHeader
			}
		} else {
			// this is for non-ecommerce
			logoUrl = oStorage.get('logo')
		}

		useEffect(() => {
			;(async () => {
				const language = User.preferredLanguage || Infra.appParams.l
				const { host, protocol } = window.location
				if (Infra?.appParams?.eCommerce) {
					const footer = Infra?.eCommerceFooter ?? (await sendRequest(false, `/${language}/footer.json`, 'get')) ?? {}
					// Iterate through sections and extract termsOfUse and privacyPolicy
					let policyPath = ''
					let termsPath = ''
					for (const section of footer.sections) {
						const findPolicyItem = section?.items?.find((item) => item.itemId === 'privacyPolicy')
						const findTermsItem = section?.items?.find((item) => item.itemId === 'termsOfUse')
						if (findPolicyItem) {
							policyPath = findPolicyItem.path
						}
						if (findTermsItem) {
							termsPath = findTermsItem.path
						}
						if (policyPath && termsPath) {
							break
						}
					}
					const policy = `${policyPath || `${protocol}//${host}/${language}/privacy-policy`}.html`
					const terms = `${termsPath || `${protocol}//${host}/${language}/terms-of-use`}.html`
					setPrivacyPolicy(policy)
					setTermsConditions(terms)
				}
			})()
		}, [Infra.appParams.l, User.preferredLanguage])

		useEffect(() => {
			if (signUpStep && Infra?.locale?.msg) {
				const _localDateTime = formatLocalDateTime(Infra.locale.msg)
				const orderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP

				sendCustomEvent({
					category: 'account',
					action: 'signup step',
					label: signUpStep,
					date: _localDateTime,
					storeID: store?.data?.id || '',
					storeName: getStoreName(store, orderType, Home) || '',
					page_path: window.location.pathname,
					page_location: window.location.href,
				})
			}
		}, [signUpStep])

		const handleClose = async (redirect = true) => {
			Infra.setLoading(true)
			await openSignUpPopUp(false, { onSignupPopupClose: redirect ? undefined : () => {} })
			Infra.setLoading(false)
		}

		const handleSkipClick = (redirect = true) => {
			skip(Infra.locale.msg, store)

			handleClose(redirect)
		}

		const getSignUpStep = () => {
			let html

			switch (signUpStep) {
				case SIGN_UP_STEPS.VERIFY_IDENTIFIER:
					html = <VerifyIdentifier onSkipClick={handleSkipClick} />
					break
				case SIGN_UP_STEPS.EDIT_CODE:
					html = <VerifyCode onSkipClick={handleSkipClick} login={doLogin} />
					break
				case SIGN_UP_STEPS.EXTEND_USER_DETAILS:
					html = <ExtendUserDetails onSkipClick={() => handleSkipClick(false)} login={doLogin} />
					break
				default:
					html = <div />
			}

			return html
		}

		const renderMobile = () =>
			signUpPopUp && (
				<SignUpContainer id="signUpContainer">
					<StyledGridRoot container>
						<AccountStepsMobileHeader onClose={handleClose} />
						<Grid container style={{ height: 'calc(100% - 50px)' }}>
							<StyledLogo $logoUrl={logoUrl} fullscreen />
							{signUpPopUp && getSignUpStep()}
						</Grid>
					</StyledGridRoot>
				</SignUpContainer>
			)

		const doLogin = async () => {
			await login(
				User,
				Infra.locale?.region || '',
				AddressManager.setFullAddress,
				AddressManager.setTempAddressByOrderType,
				AddressManager.localizedAddress.delivery.formatted_address,
				store,
				Application.backendChannel
			)

			const userTokens = getUserTokens()
			if (signUpStep === SIGN_UP_STEPS.EXTEND_USER_DETAILS || (userTokens.user.firstName && userTokens.user.email)) {
				const showSpecialDealsAndDiscountsPopup = checkPushNotificationsStatus(MobileApplication.pushNotificationSupported)
				if (showSpecialDealsAndDiscountsPopup) {
					showDoesUserWantsToBeNotifiedInSpecialDealsAndDiscountsPopup()
				}
			}
		}

		const showDoesUserWantsToBeNotifiedInSpecialDealsAndDiscountsPopup = () => {
			Infra.setNotification({
				open: true,
				message:
					User?.userLocaleLanguage?.eCommerce?.notificationPermission ||
					'Would you like to be notified about our special deals and discounts ?',
				cancelAction: () => {
					Infra.closeNotification()
					MobileApplication.setPushNotificationsDeclineDate()
				},
				onClose: () => {
					MobileApplication.setPushNotificationsDeclineDate()
				},
				okAction: () => {
					ReactNativeComms.sendMessage.askPushNotifications()
					Infra.closeNotification()
				},
				okText: getTranslatedTextByKey('btns.yes', 'Yes'),
				cancelText: getTranslatedTextByKey('btns.no', 'No'),
				showCancelButton: true,
			})
		}

		const renderDesktop = () => (
			<StyledModal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={signUpPopUp}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={signUpPopUp}>
					<StyledPaper data-testid="account-modal" $reCaptchaEnabled={reCaptchaEnabled} $friendlyCaptchaEnabled={friendlyCaptchaEnabled}>
						<StyledTopGrid>
							<StyledButton edge="end" color="inherit" aria-label="menu" onClick={handleClose}>
								<StyledCloseIcon />
							</StyledButton>
						</StyledTopGrid>
						<StyledBottomGrid>
							<StyledLogo $logoUrl={logoUrl} />
							{signUpPopUp && getSignUpStep()}
						</StyledBottomGrid>
					</StyledPaper>
				</Fade>
			</StyledModal>
		)

		return isMobileView ? renderMobile() : renderDesktop()
	})
)

export default AccountStepsManager
