// @ts-nocheck
import React, { useContext, useEffect } from 'react'
import Badge from '@material-ui/core/Badge'
import { inject, observer } from 'mobx-react'
import IconComponent from '../../themes/IconComponent'
import styled from 'styled-components'
import { useTheme } from '@material-ui/core/styles'
import { sendEnhancedEcommerceEvent } from 'utils/analytics/analytics'
import { CONSTANTS } from 'utils/constants'
import { StoreContext } from 'contexts/StoreContext'

const StyledCartIcon = styled(IconComponent).attrs({
	asset: '/icons/cart-non-empty.svg',
})`
	width: 76px;
	height: 76px;

	@media (max-width: 576px) {
		width: 50px;
		height: 50px;
	}
`

const StyledEmptyCartIcon = styled(IconComponent).attrs({
	asset: '/icons/cart.svg',
})`
	width: 76px;
	height: 76px;

	@media (max-width: 576px) {
		width: 50px;
		height: 50px;
	}
`

const StyledBadge = styled(Badge)`
	&.MuiBadge-root {
		margin-left: 20px;

		@media (max-width: 576px) {
			margin-left: 5px;
		}
	}
	cursor: pointer;
	.MuiBadge-anchorOriginTopRightRectangular {
		height: 23px;
		${({ theme }) => theme.typography.BodySmall};
		min-width: 23px;
		border-radius: 12px;
		background-color: #0000;
		color: var(--topperBtnFontColor);
		margin: ${({ $offsetTop, $offsetRight }) => `${23 + $offsetTop}px ${20 + $offsetRight}px 0 0`};

		@media (max-width: 576px) {
			height: 16px;
			min-width: 16px;
			border-radius: 8px;
			margin: ${({ $offsetTopMobile, $offsetRightMobile }) => `${13 + $offsetTopMobile}px ${13 + $offsetRightMobile}px 0 0`};
		}
	}
`

const EEE = CONSTANTS.ANALYTICS.ENHANCED_ECOMMERCE_EVENTS

const ShoppingCart = inject('Cart')(
	observer((props) => {
		const { Cart, setShowCartSummary, showCartSummary } = props
		const { store, nextJS } = useContext(StoreContext)
		const rest = store.data

		const theme = useTheme()

		useEffect(() => {
			const isMenu = window.location.pathname.includes('/menu')

			if (showCartSummary && !isMenu) {
				sendEnhancedEcommerceEvent(EEE.sendViewCartEvent, rest, Cart)
			}
		}, [showCartSummary])

		const itemsTotalQuantity = Object.values(Cart.items)
			.flat()
			.reduce((acc, curr) => acc + curr.quantity, 0)

		return (
			<StyledBadge
				key={nextJS?.router.asPath}
				badgeContent={<span data-testid="cart-items-quantity-badge">{itemsTotalQuantity || ''}</span>}
				color="secondary"
				data-testid="shopping-cart-btn"
				$offsetTop={theme?.options?.cartBadge?.desktop?.top ?? 0}
				$offsetRight={theme?.options?.cartBadge?.desktop?.right ?? 0}
				$offsetTopMobile={theme?.options?.cartBadge?.mobile?.top ?? 0}
				$offsetRightMobile={theme?.options?.cartBadge?.mobile?.right ?? 0}
				overlap="rectangular"
				onClick={() => setShowCartSummary(!showCartSummary)}
			>
				{!!Cart.calcNumberOfItems && <StyledCartIcon />}
				{!Cart.calcNumberOfItems && <StyledEmptyCartIcon />}
			</StyledBadge>
		)
	})
)

export default ShoppingCart
