// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import TypographyPro from 'themes/TypographyPro'

const Title = styled(TypographyPro).attrs(({ variant }) => ({
	variant: variant || 'h2',
}))`
	text-transform: uppercase;
	color: var(--strokeMenuTitle);

	@media (max-width: 577px) {
		margin: 10px 0;
	}
`

export default Title
