// @ts-nocheck
import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import IconButton from '@material-ui/core/IconButton'

import { isRTL } from 'utils/utils'
import TypographyPro from 'themes/TypographyPro'
import { Logo, LOGO_TYPE } from 'components/common/Logo'
import ShoppingCart from 'components/common/ShoppingCart'
import HamburgerMenuButton from 'components/header/HamburgerMenuButton'
import { HeaderSectionLeft, HeaderSectionRight } from 'components/header/header-styles'
import Link from 'components/common/RouterLink'

const useStyles = makeStyles((theme) => ({
	mobileAppBar: {
		height: '64px',
		boxShadow: 'none',
		backgroundColor: 'white',
		color: 'black',
		padding: '0 22px',
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		perspective: '1000px',
	},
	backLink: {
		color: '#686767', // theme.palette.primary.main,
	},
}))

const HeaderLogo = styled(Logo)`
	width: 100vw;
	height: 100vh;
	max-width: 106px;
	max-height: 43px;
	object-fit: contain;
`

const LogoWrapper = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
`

const FlexWrapper = styled.div`
	display: flex;
`

export const MobileAppHeader = inject(
	'Cart',
	'User',
	'Payment',
	'Infra',
	'Application'
)(
	observer((props) => {
		const classes = useStyles()

		const { Application, User, Payment, className, setShowCartSummary, showCartSummary, showCart } = props

		const goBack = () => {
			if (typeof props.customGoBack !== 'undefined') {
				props.customGoBack()
			} else {
				if (Payment.isFormEditable) {
					Payment.setFormEditable(false)
				}

				return window.history.back()
			}
		}

		return (
			<AppBar className={classes.mobileAppBar} position="static">
				<HeaderSectionLeft>
					{!props.showBack && (
						<>
							<HamburgerMenuButton id={`${Application.page}-page-app-menu`} />
							<LogoWrapper>
								<HeaderLogo className={className} logoType={LOGO_TYPE.HEADER} />
							</LogoWrapper>
						</>
					)}
					{props.showBack && (
						<FlexWrapper id={`${Application.page}-page-return-button`} className="">
							<IconButton
								edge="start"
								color="inherit"
								aria-label="back"
								onClick={goBack}
								style={isRTL(User.session.locale) ? { transform: 'rotate(180deg)' } : {}}
								data-testid="back-button-header-mobile"
							>
								<ArrowBackIosIcon className={classes.backLink} />
							</IconButton>
							<LogoWrapper>
								<HeaderLogo className={className} logoType={LOGO_TYPE.HEADER} />
							</LogoWrapper>
							<TypographyPro variant="h5">{(props.backTitle ?? '').toUpperCase()}</TypographyPro>
						</FlexWrapper>
					)}
				</HeaderSectionLeft>
				{showCart && (
					<HeaderSectionRight>
						<Link to={`/summary${window.location.search}`}>
							<ShoppingCart setShowCartSummary={setShowCartSummary} showCartSummary={showCartSummary} />
						</Link>
					</HeaderSectionRight>
				)}
			</AppBar>
		)
	})
)

export default MobileAppHeader
