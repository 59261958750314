// @ts-nocheck
import React from 'react'
import Modal from '@material-ui/core/Modal'
import styled from 'styled-components'
import Title from './Title'
import { Button, IconButton } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

const ModalContainer = styled.div`
	background-color: white;
	height: 600px;
	width: 550px;
	margin: auto;
	position: relative;
	top: 103px;
	display: flex;
	border-radius: 10px;
	flex-direction: column;
	@media (max-width: 576px) {
		height: 100%;
		width: 100%;
		top: 0;
		border-radius: 0;
	}
`

const ModalContent = styled.div`
	text-align: center;
	max-width: 380px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 110px auto 0 auto;
`

const StyledButton = styled(Button)`
	border: 1.5px solid #202124;
	box-sizing: border-box;
	border-radius: 66px;
	width: 242px;
	margin: 10px 0;
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
`

const StyledIconButton = styled(IconButton)`
	position: absolute;
	right: 0;
`

const StyledSubText = styled.h2`
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
`

const Popup = ({ open, onClose, onClickHandler, buttonsInfo, showClearIcon = false, titleText, subTitleText, children }) => (
	<Modal open={open} onClose={onClose}>
		<ModalContainer data-testid="choose-order-type-popup">
			{showClearIcon && (
				<StyledIconButton onClick={onClose}>
					<ClearIcon />
				</StyledIconButton>
			)}
			<ModalContent>
				<Title>{titleText}</Title>
				<StyledSubText>{subTitleText}</StyledSubText>
				{children}
			</ModalContent>
		</ModalContainer>
	</Modal>
)

export default Popup
