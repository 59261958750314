// @ts-nocheck
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { respondAbove } from 'styles/mixins'
import ButtonBase from 'components/common/ButtonBase'

export const SubmitStyledButton = styled(ButtonBase)`
	text-transform: uppercase;
	width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
	max-width: 90vw;

	${respondAbove.md`
		width: auto;
		max-width: unset;
	`}
`

export const SubmitButtonContainer = styled(Grid)`
	justify-content: center;
	margin-top: 30px;
`

export const TitleContainer = styled(Grid)`
	text-align: center;
	text-transform: uppercase;
	justify-content: center;
	color: var(--strokeMenuTitle);
	align-items: center;
	margin-top: 16px;
	align-self: baseline;

	${respondAbove.md`
		align-self: unset;
	`}
`

export const Form = styled.form`
	margin-top: 100px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;

	${respondAbove.md`
		  margin-top: 50px;
  	`}
`

export const StyledTextField = styled(TextField)`
	width: 100%;
	line-height: 24px;
	letter-spacing: -0.1px;
	text-align: start;

	& .MuiInputBase-root {
		${({ theme }) => theme.typography.BodyRegular};
	}

	& .MuiFormLabel-root {
		${({ theme }) => theme.typography.BodyRegular};
	}

	& label.Mui-focused {
		color: var(--inactive) !important;
	}

	& .MuiInput-underline:after {
		border-bottom: 1px solid var(--fonts) !important;
	}
`

export const StyledGridContainer = styled(Grid)`
	padding: 0 20px;
	width: 100%;
	height: calc(100% - 135px);

	${respondAbove.md`
		padding: 0 36px;
		display: flex;
		flex-direction: column;
		height: 456px;
		width: unset;
  	`}
`
