import { useRouter } from 'next/router'
import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components/macro'
import { respondAbove } from 'styles/mixins'

import IconComponent from 'themes/IconComponent'

import type { ReactElement } from 'react'
import type _Infra from 'mobx/Infra'
import type _Home from 'mobx/Home'
import type _AddressManager from 'mobx/AddressManager'

const PinIcon = styled(IconComponent).attrs({
	asset: '/icons/locationPinHeader.svg',
})`
	height: 18px;
	width: 18px;
	margin-inline-end: 5px;
`

const EditIcon = styled(IconComponent).attrs({
	asset: '/icons/edit-localized.svg',
})`
	height: 25px;
	width: 25px;
	margin-inline-start: 20px;
`

const Address = styled.div`
	color: var(--strokeMenuTitle);
	display: grid;
	grid-template-columns: auto auto;
`

const Container = styled.div`
	display: grid;
	grid-template-columns: auto auto auto;
	align-items: center;
	cursor: pointer;

	${respondAbove.md`
		padding: 8px;
	`};

	${respondAbove.lg`
		border: 1px solid var(--highlights);
	`}
`

const Layout = styled.div`
	padding: 15px 20px 10px 20px;

	${respondAbove.md`
		padding: 15px 40px 10px 40px;
	`}

	${respondAbove.lg`
		padding: 0 40px 10px 40px;
	`}
`

interface LocalizedAddressBarProps {
	address: ReactElement
	Infra: typeof _Infra
	Home: typeof _Home
	AddressManager: typeof _AddressManager
	page: string
}

const LocalizedAddressBar = ({ address, Infra, Home, AddressManager, page }: LocalizedAddressBarProps) => {
	const router = useRouter()

	const handleEdit = () => {
		if (
			router.pathname !== '/' &&
			router.pathname !== '/home' &&
			!(Infra?.appParams?.features?.staticPagesWithAddressBox as string[] | undefined)?.includes(page)
		) {
			router.push({ pathname: '/' })
		}

		Home.setBackdropEnabled(true)
		AddressManager.setShowLocalizedAddress(false)
	}

	return (
		<Layout>
			<Container onClick={handleEdit}>
				<PinIcon />
				<Address>{address}</Address>
				<EditIcon testId="localized-edit-icon" />
			</Container>
		</Layout>
	)
}

export default inject('Infra', 'Home', 'AddressManager')(observer(LocalizedAddressBar))
