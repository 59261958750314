// @ts-nocheck
import React from 'react'
import { inject, observer } from 'mobx-react'
import { Fade, Slide } from '@material-ui/core'

const SlideRoute = inject('Application')(
	observer((props) => {
		const { Application, children, ...rest } = props
		const { isMobileApp } = Application

		if (isMobileApp) {
			return (
				<Slide direction="up" in mountOnEnter unmountOnExit timeout={500}>
					<div>
						<Fade in timeout={1000}>
							<div>{children}</div>
						</Fade>
					</div>
				</Slide>
			)
		}

		return children
	})
)

export default SlideRoute
