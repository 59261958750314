import React, { useContext } from 'react'
import { HeaderLink, StyledHeaderLinksContainer } from '../header-styles'
import { useHistory } from 'react-router-dom'
import { StoreContext } from 'contexts/StoreContext'
import SmartLink from 'static/components/SmartLink'
import { isNextJS } from '../../../../utils/nextUtils'
import { inject, observer } from 'mobx-react'
import type { Page } from 'utils/constants'
import type { Menu } from 'types/Menu'
import { routeToPage } from 'utils/utils'
import { toJS } from 'mobx'

const HeaderLinks = ({
	Application,
	headerData,
	isMobileApp,
	isUserLocalized,
	setUserSession,
	onMenuClickWhenLocalized,
	allStoresClosed,
}: {
	Application: { page: Page }
	headerData?: { sections?: { path: string; itemType: string; sectionId: string; text: string }[] }
	isMobileApp?: boolean
	isUserLocalized?: boolean
	allStoresClosed?: boolean
	setUserSession: (serverSession: any) => Promise<void>
	onMenuClickWhenLocalized: () => Promise<{ menuRes: Menu; storeMetaData: any; serverSession: any; menuUrl: string }>
}) => {
	const history = useHistory()
	const { setStore, nextJS } = useContext(StoreContext)
	const isGeneratedStatically = !isMobileApp

	return (
		<StyledHeaderLinksContainer>
			{headerData?.sections?.map((headerLink) => {
				const onMenuClick = async () => {
					const { menuRes, storeMetaData, serverSession, menuUrl } = await onMenuClickWhenLocalized()
					await setUserSession(serverSession)

					setStore((store) => ({ ...store, data: menuRes, metaData: storeMetaData }))

					if (isNextJS()) {
						nextJS.router.push(`${menuUrl}`)
						return
					}

					if (history) {
						history.push(`${menuUrl}`)
					} else {
						window.location.replace(`${window.location.origin}${menuUrl}`)
					}
				}

				if (!allStoresClosed && isUserLocalized && headerLink.path.includes('menu')) {
					return (
						<HeaderLink
							id={`${Application.page}-page-${routeToPage(headerLink.path)}-link`}
							component="a"
							variant="BodySmall"
							key={headerLink.sectionId}
							onClick={onMenuClick}
							data-testid={`${headerLink.path?.slice(headerLink.path.lastIndexOf('/') + 1)}-page-link`}
						>
							<HeaderLink component="div" variant="h4" $isHome>
								{headerLink.text}
							</HeaderLink>
						</HeaderLink>
					)
				}

				return (
					<SmartLink
						id={`${Application.page}-page-${routeToPage(headerLink.path)}-link`}
						sectionItem={headerLink}
						key={headerLink.sectionId}
						isGeneratedStatically={isGeneratedStatically}
						openInSameBrowserTab={headerLink.itemType !== 'external'}
						testId={`${headerLink.path?.slice(headerLink.path.lastIndexOf('/') + 1)}-page-link`}
					>
						<HeaderLink component="div" variant="h4" $isHome>
							{headerLink.text}
						</HeaderLink>
					</SmartLink>
				)
			})}
		</StyledHeaderLinksContainer>
	)
}

export default inject('Application')(observer(HeaderLinks))
