// @ts-nocheck
import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import getElementByIdWrapper from 'utils/getElementByIdWrapper'
import { respondAbove } from 'styles/mixins'
import styled from 'styled-components/macro'
import type _Application from 'mobx/Application'
import type _Infra from 'mobx/Infra'
import type _Home from 'mobx/Home'
import type _Cart from 'mobx/Cart'
import type _User from 'mobx/User'
import type _MobileApplication from 'mobx/MobileApplication'
import type _AddressManager from 'mobx/AddressManager'
import type _Payment from 'mobx/Payment'
import { getTranslatedTextByKey, getCurrentLocation, reverseGeoCode, initSessionAndLoadMenu } from 'utils/utils'
import Button from '@material-ui/core/Button'
import { StoreContext } from 'contexts/StoreContext'
import { useLocationMobileApp } from 'hooks/useLocationMobileApp'
import storage from 'utils/storage'
import ReactNativeComms from 'utils/reactNativeComms'
import HomepageMapIcon from 'components/common/HomepageMapIcon'
import googlePlacesCallbackHandler from 'components/home/ModalContent/googlePlacesCallbackHandler'
import TypographyPro from 'themes/TypographyPro'
import { ORDER_TYPES, CONSTANTS, Page, LOCALIZATION_TYPE } from 'utils/constants'
import { sendCustomEvent } from 'utils/analytics/analytics'
import NewAddressBoxGooglePlacesAutoComplete from '../../common/address/NewAddressBoxGooglePlacesAutoComplete'
import { useHistory } from 'react-router-dom'
import loadMenu from 'utils/api/loadMenu/loadMenu'
import { isNextJS } from '../../../../utils/nextUtils'
import { FeatureFlagEnum } from 'types/constants.types'
import { useRouter } from 'next/router'

const Container = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 100%;
	flex-direction: column;
	gap: 5px;
	margin-top: 15px;
`

const StyledButton = styled(Button)`
	background-color: var(--clear) !important;
	border: 0.5px solid var(--primary);
	border-radius: var(--borderRadiusInput);
	box-shadow: none;
	min-width: unset;
	width: 47px;

	${respondAbove.lg`
		width: 52px;
		height: 40px;
	`}
`

const MapAndInputContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
`

const StyledHomepageMapIcon = styled(HomepageMapIcon)`
	width: 27px;
	height: 27px;

	path {
		fill: var(--primary);
	}

	${respondAbove.md`
		width: 32px;
		height: 32px;
	`}
`

const StyledHomepageMapIconLink = styled(HomepageMapIcon)`
	margin-right: 5px;
	width: 25px;
	height: 25px;

	path {
		fill: var(--primary);
	}
`

const EnterAddressText = styled(TypographyPro)`
	color: var(--strokeMenuTitle);
`

const ClickOnMapLink = styled(TypographyPro)`
	margin-top: 10px;
	cursor: pointer;
	color: var(--primary);
	display: flex;
	align-items: flex-end;
`

const AddressSearch = inject(
	'Home',
	'Infra',
	'Application',
	'MobileApplication',
	'Cart',
	'AddressManager',
	'User'
)(
	observer(
		({
			Home = {} as typeof _Home,
			AddressManager = {} as typeof _AddressManager,
			MobileApplication = {} as typeof _MobileApplication,
			Infra = {} as typeof _Infra,
			Application = {} as typeof _Application,
			Cart = {} as typeof _Cart,
			User = {} as typeof _User,
			isPopupOpen,
			backdropEnabled,
		}: {
			Application?: typeof _Application
			User?: typeof _User
			Cart?: typeof _Cart
			Infra?: typeof _Infra
			MobileApplication?: typeof _MobileApplication
			AddressManager?: typeof _AddressManager
			Home?: typeof _Home
			isPopupOpen: boolean
			backdropEnabled: boolean
		}) => {
			const [lastAddressLocation, setLastAddressLocation] = useState({})
			const { userLocation, userLocationPermissionStatus, setLocationPermissionsStatus } = MobileApplication
			const { isMobileApp } = Application
			const isCurrentOrderTypeDelivery = useMemo(() => User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY, [User.orderType])
			const { setStore, nextJS } = useContext(StoreContext)
			const router = useRouter()
			const itemPopupTop = Infra?.hasFeatureFlag(FeatureFlagEnum.ADDRESS_BOX_POPUP_TOP)

			const onLocationPermissionGranted = async (location) => {
				const geocodeRes = await reverseGeoCode({ lat: location.lat, lng: location.long })
				const orderType = isCurrentOrderTypeDelivery ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP
				AddressManager.setTempAddressByOrderType(geocodeRes, orderType)
				// after geolocation we don't use the location data anymore but the geocode data instead to avoid
				// mismatches between address and coordinates
				googlePlacesCB(geocodeRes, 1)
				Infra.setLoading(false)
				setLocationPermissionsStatus(CONSTANTS.MOBILE_APP.PERMISSIONS.LOCATION.NOT_ASKED)

				sendCustomEvent({
					category: 'address box',
					action: 'accepted',
					label: 'use my location',
					position: { lat: location.lat, lng: location.long },
				})
			}

			const onLocationPermissionDenied = async (err) => {
				Infra.setLoading(false)
				if (
					err?.message === 'User denied Geolocation' ||
					userLocationPermissionStatus === CONSTANTS?.MOBILE_APP?.PERMISSIONS?.LOCATION?.DENIED
				) {
					const title = getTranslatedTextByKey('webviewFlow.locationPermission', 'Location Permission')
					const message = getTranslatedTextByKey(
						'webviewFlow.pleaseAllowLocationPermission',
						'Please allow the location permission on your settings.'
					)

					Infra.setNotification({
						title,
						open: true,
						message: (
							<span>
								{message}
								&nbsp;
							</span>
						),
						okAction: () => {
							Infra.closeNotification()
						},
						okText: getTranslatedTextByKey('webviewFlow.ok', 'OK'),
						showCancelButton: false,
					})

					sendCustomEvent({
						category: 'error',
						action: 'notification',
						label: title,
						message,
					})
				}

				setLocationPermissionsStatus(CONSTANTS.MOBILE_APP.PERMISSIONS.LOCATION.NOT_ASKED)
			}

			if (isMobileApp) {
				useLocationMobileApp(onLocationPermissionGranted, onLocationPermissionDenied, userLocationPermissionStatus, userLocation)
			}

			const _getAddressInfo = useCallback(() => {
				const currentOrderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP
				const { formatted_address, storeId } = AddressManager.getFullAddressByOrderType(currentOrderType)

				if (formatted_address) {
					const { lat, lng } = AddressManager.getAddressCoordinatesByOrderType(currentOrderType)
					if (lat !== 0 && lng !== 0) {
						setLastAddressLocation({ value: storeId, label: formatted_address, lastAddress: true })
					}
				}
			}, [])

			// in order to address stores with no chain (single stores) we check on mount if the URL has the j param
			// this is the param that denotes a storeId and will make the homepage go straight to the menu
			// in order to load a single store we will use the following URL:
			// cdn.tictuk.com/home?l=<lang>&c=<chainId>&ref=chainWeb&j=<storeId>&ot=<'peakup'/'delivery'>
			useEffect(() => {
				const loadSingleStoreMenu = async () => {
					const { appParams } = Infra
					const isEcommerce = appParams?.eCommerce

					const queryParams = queryString.parse(window.location.search)

					// if there's no order type we don't go to the menu since we don't know which order type needs to be used
					if (!appParams.ot && appParams.j) {
						console.error(
							`The menu for store id: ${appParams.j} cannot be loaded since an error has occurred since the ot parameter was not provided in the URL.`
						)
					} else if (appParams.j) {
						// When getting here when loading the home page the previous page can be a few things:
						// 1. Store locator after selecting store and get the query params (j, c, l, ref, ot).
						// 2. Any other page when we are going back to the menu using the React router history hook.
						// 3. Not an E-commerce and should redirect back to the homepage as a defence process
						const queryParamsKeys = Object.keys(queryParams)
						if (!isEcommerce) {
							await initSessionAndLoadMenu(loadMenu, storage)(false, appParams.j, router, setStore, Home, Cart)
						}
						if (
							queryParamsKeys.includes('ot') &&
							queryParamsKeys.includes('j') &&
							queryParamsKeys.includes('c') &&
							// queryParamsKeys.includes('ref') &&
							queryParamsKeys.includes('l')
						) {
							User.setOrderType(queryParams.ot)
							await initSessionAndLoadMenu(loadMenu, storage)(
								false,
								appParams.j,
								router,
								setStore,
								Home,
								Cart,
								null,
								User.orderType,
								true
							)
						}
						getElementByIdWrapper('appBarHeader', (element) => {
							element.style.display = ''
						})
						getElementByIdWrapper('homeContainer', (element) => {
							element.style.display = ''
						})
					}

					Infra.setLoading(false)
				}
				loadSingleStoreMenu()
			}, [])

			useEffect(() => {
				_getAddressInfo()
			}, [isCurrentOrderTypeDelivery, AddressManager.localizedAddress])

			const handleMapIconClick = () => {
				sendCustomEvent({
					category: 'address box',
					action: 'clicked',
					label: 'map icon',
					page_location: window.location.href?.split('?')[0],
				})
				AddressManager.setAddressPopup({ mapButtonClicked: true })
				AddressManager.setLoading(true)
				googlePlacesCB()
			}

			const handleMapLinkClick = () => {
				sendCustomEvent({
					category: 'address box',
					action: 'clicked',
					label: 'map link',
					page_location: window.location.href?.split('?')[0],
				})
				AddressManager.setAddressPopup({ mapButtonClicked: true })
				AddressManager.setLoading(true)
				googlePlacesCB()
			}

			const handleOnlyOneStoreFoundForPickup = (store) => ({
				formatted_address: store.address.formatted,
				geometry: {
					location: {
						lat: store.address.latLng.lat,
						lng: store.address.latLng.lng,
					},
				},
			})

			const googlePlacesCB = async (data, fromType = LOCALIZATION_TYPE.NONE) => {
				const selectedOrderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP

				if (data?.lastAddress) {
					sendCustomEvent({
						category: 'address box',
						action: 'click',
						label: 'last address',
						page_location: window.location.href?.split('?')[0],
					})

					onSubmit({ id: data.value }, { formatted_address: data.label })

					sendCustomEvent({
						category: 'address box',
						action: 'finish',
						label: 'last address',
						storeId: data.value,
						orderType: selectedOrderType,
						page_location: window.location.href?.split('?')[0],
					})

					AddressManager.setLoading(false)
					return
				}

				try {
					const res = await googlePlacesCallbackHandler(data, User.getOrderType())
					let addressObj = res?.addressObject

					const storesFoundRelevantForAddress = Home.stores.filter(
						({ availability }) =>
							availability?.[selectedOrderType]?.availableForAddress && (availability.orderOnline || availability.preOrder)
					)

					const storeFound = storesFoundRelevantForAddress?.[0]
					// this is for redirecting straight to the menu if only 1 store found or shouldGoToFirstStoreFound is true and relevant stores found
					if (storesFoundRelevantForAddress.length === 1 || (res?.shouldGoToFirstStoreFound && storesFoundRelevantForAddress.length)) {
						if (User.getOrderType() === CONSTANTS.DELIVERY_METHODS.PICKUP) {
							addressObj = handleOnlyOneStoreFoundForPickup(storesFoundRelevantForAddress?.[0])
						}
						onSubmit(storeFound, addressObj)

						if (fromType === LOCALIZATION_TYPE.FROM_MY_LOCATION) {
							sendCustomEvent({
								category: 'address box',
								action: 'finish',
								label: 'use my location',
								storeId: storeFound.id,
								orderType: selectedOrderType,
								page_location: window.location.href?.split('?')[0],
							})
						} else if (fromType === LOCALIZATION_TYPE.FROM_SELECT) {
							sendCustomEvent({
								category: 'address box',
								action: 'select address from drop down',
								label: 'test',
								storeId: storeFound.id,
								orderType: selectedOrderType,
								page_location: window.location.href?.split('?')[0],
							})
						}
					} else {
						AddressManager.setAddressPopup({ ...AddressManager.addressPopup, open: res.modalOpen, onSubmit })
					}
				} catch (err) {
					console.error(err)
				}

				AddressManager.setLoading(false)
			}

			const getLocation = () => {
				sendCustomEvent({
					category: 'address box',
					action: 'click',
					label: 'use my location',
					page_location: window.location.href?.split('?')[0],
				})

				Infra.setLoading(true)
				if (isMobileApp) {
					setLocationPermissionsStatus(CONSTANTS.MOBILE_APP.PERMISSIONS.LOCATION.NOT_ASKED)
					ReactNativeComms.sendMessage.askForLocation()
				} else {
					getCurrentLocation(onLocationPermissionGranted, onLocationPermissionDenied)
				}
			}

			const onSubmit = async ({ id: storeId, title }, addressObject) => {
				if (!storeId) {
					return
				}
				addressObject = {
					...addressObject,
					title,
				}
				const orderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP
				AddressManager.setFullAddress(addressObject, orderType, false, true)
				AddressManager.setMatchingStoreIdToAddressByOrderType(storeId, orderType)

				// We reset delivery fee for peakup
				if (!isCurrentOrderTypeDelivery) {
					const estimatedDeliveryCharge = {
						amount: 0,
						type: ORDER_TYPES.PEAKUP,
					}
					Cart.setServerCharges((Cart.serverCharges ?? []).filter((item) => item.type !== ORDER_TYPES.DELIVERY))
					localStorage.setItem('estimatedDeliveryFee', JSON.stringify(estimatedDeliveryCharge))
				}

				User.setOrderType(orderType)
				await initSessionAndLoadMenu(loadMenu, storage)(false, storeId, router, setStore, Home, Cart)
				AddressManager.setShowLocalizedAddress(true)
			}

			return (
				<Container>
					<EnterAddressText variant="BodyRegularHighlighted">
						{getTranslatedTextByKey('webviewFlow.localization.enterAddress', 'Enter your address')}
					</EnterAddressText>
					<MapAndInputContainer>
						<NewAddressBoxGooglePlacesAutoComplete
							onPlaceSelected={googlePlacesCB}
							getLocation={getLocation}
							lastAddressLocation={lastAddressLocation}
							highlightUseMyLocationBtn={isPopupOpen}
							backdropEnabled={backdropEnabled}
						/>

						{!itemPopupTop && (
							<StyledButton
								variant="contained"
								onClick={handleMapIconClick}
								id={`${Application.page}-page-map-icon`}
								data-testid="open-map-btn"
							>
								<StyledHomepageMapIcon />
							</StyledButton>
						)}
					</MapAndInputContainer>

					{itemPopupTop && (
						<ClickOnMapLink
							variant="BodyRegular"
							onClick={handleMapLinkClick}
							id={`${Application.page}-page-map-icon`}
							data-testid="open-map-btn"
						>
							<StyledHomepageMapIconLink />
							{getTranslatedTextByKey('webviewFlow.localization.findAddressOnTheMap', 'Find address on the map')}
						</ClickOnMapLink>
					)}
				</Container>
			)
		}
	)
)

export default AddressSearch
