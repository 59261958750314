// @ts-nocheck
import React, { useCallback, useContext, useEffect } from 'react'
import IconComponent from '../../themes/IconComponent'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import { inject, observer } from 'mobx-react'
import TypographyPro from 'themes/TypographyPro'
import { respondAbove } from '../../styles/mixins'
import useWindowState from 'hooks/useWindowState'
import { StoreContext } from 'contexts/StoreContext'
import { getTranslatedTextByKey } from 'utils/utils'
import { HISTORY_WINDOW_STATE_ID } from 'utils/constants'

const AccountSignedInIcon = (props) => <IconComponent useThemeDefaultColor asset="/icons/AccountSignedIn.svg" {...props} />

const StyledAccountSignedInIcon = styled(AccountSignedInIcon)`
	width: 30px;
	height: 30px;
`

const AccountNotSignedInIcon = (props) => <IconComponent useThemeDefaultColor asset="/icons/AccountNotSignedIn.svg" {...props} />

const StyledAccountNotSignedInIcon = styled(AccountNotSignedInIcon)`
	width: 30px;
	height: 30px;
`

const UserGreeting = styled(TypographyPro)`
	cursor: pointer;
	color: var(--inactive);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 80px;

	${({ $signedIn }) => !$signedIn && 'display: none;'}

	${respondAbove.sm`
		display: initial;
		max-width: 250px;
	`}
`

export default inject(
	'Account',
	'Application',
	'Infra'
)(
	observer((props) => {
		const { Account, Application, Infra } = props
		const { nextJS } = useContext(StoreContext)
		const { openSignUpPopUp } = Account
		const authIdentifiers = Infra.getAuthIdentifiers()
		const [addState] = useWindowState()

		const handleOpenSignUpPopup = useCallback(() => {
			addState(() => {
				if (window.history?.state?.url?.includes(HISTORY_WINDOW_STATE_ID)) {
					openSignUpPopUp(false, { onSignupPopupClose: () => {} })
				}
			}, HISTORY_WINDOW_STATE_ID)

			openSignUpPopUp(true)
		}, [addState, openSignUpPopUp])

		const redirectToAccountPage = useCallback(() => {
			nextJS.router.push('/account')
		}, [])

		if (!authIdentifiers.length) {
			return null
		}

		return (
			<div id={`${Application.page}-page-sign-in-button`}>
				<IconButton
					data-testid="account-icon"
					edge="start"
					color="inherit"
					aria-label="back"
					style={{ padding: 3 }}
					onClick={Account.user ? redirectToAccountPage : handleOpenSignUpPopup}
				>
					{Account.userExists ? <StyledAccountSignedInIcon /> : <StyledAccountNotSignedInIcon />}
				</IconButton>

				{Account.userExists && (
					<UserGreeting onClick={redirectToAccountPage} variant="BodyRegular" $signedIn>
						{Account.userName?.split(' ')[0]}
					</UserGreeting>
				)}

				{!Account.userExists && (
					<UserGreeting onClick={handleOpenSignUpPopup} data-testid="account-name" variant="BodyRegular" $signedIn={false}>
						{getTranslatedTextByKey('eCommerce.signIn.text')}
					</UserGreeting>
				)}
			</div>
		)
	})
)
