import type { ReactNode } from 'react'
import React, { useState } from 'react'
import AccordionBase from '@material-ui/core/Accordion'
import AccordionSummaryBase from '@material-ui/core/AccordionSummary'
import AccordionDetailsBase from '@material-ui/core/AccordionDetails'
import styled from 'styled-components/macro'
import IconComponent from 'themes/IconComponent'
import TypographyPro from 'themes/TypographyPro'
import { useStores } from 'hooks/useStores'
import Coupon from './Coupon'
import DividerBase from '../Divider'
import { getTranslatedTextByKey } from 'utils/utils'
import useCouponList from './useCouponList'
import { inject, observer } from 'mobx-react'
import { Collapse } from '@material-ui/core'
import { isEmpty } from 'lodash-es'
import type { CartType, CouponListItem } from 'components/common/coupons/types'
import CouponItemRow from 'components/common/coupons/CouponItemRow'
import { type Coupon as CouponType } from 'types/Coupons'
import { sendCustomEvent } from 'utils/analytics/analytics'
import type _Infra from 'mobx/Infra'

const ArrowContainer = styled.div`
	background: var(--optionalBackground);
	border-radius: 50%;
	padding: 10px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
`

const Arrow = styled(IconComponent)`
	width: 12px;
	height: 12px;
`
const Title = styled(TypographyPro)`
	color: var(--strokeMenuTitle);
	text-transform: uppercase;
`

const Divider = styled(DividerBase)`
	margin: 5px 0;
	background-color: var(--optionalBackground);
`

const AccordionDetails = styled(AccordionDetailsBase)`
	flex-direction: column;
	padding: 10px;
`

const AccordionSummary = styled(AccordionSummaryBase)`
	padding: 0 10px;
	height: 50px;

	&.Mui-expanded {
		min-height: 50px;
	}
`

const Accordion = styled(AccordionBase)`
	box-shadow: none;
	border: solid 1px var(--optionalBackground);
`

const LeftIcon = () => (
	<ArrowContainer>
		<Arrow asset="/icons/dropDownArrow.svg" />
	</ArrowContainer>
)

export interface ListItem {
	code: string
	text: ReactNode
	status: 'success' | 'warning'
}

interface CouponsListProps {
	expanded: boolean
	onChange: (event: React.ChangeEvent<{}>, expanded: boolean) => void
	Cart: CartType
	Infra: typeof _Infra
}

const CouponsList = inject(
	'Cart',
	'Infra'
)(
	observer(({ Cart, Infra }: CouponsListProps) => {
		const { list }: { list: CouponListItem[] } = useCouponList(Cart)
		const { couponFlowStore } = useStores()

		const isCouponApplied = !isEmpty(Cart.discounts)
		const [expanded, setExpanded] = useState(true)

		const handleUnapply = (item: CouponListItem) => {
			couponFlowStore.unapply({ code: item.code } as CouponType)
			const appliedSpecialDiscountsStr = sessionStorage.getItem('appliedSpecialDiscounts')
			const appliedSpecialDiscounts = appliedSpecialDiscountsStr ? JSON.parse(appliedSpecialDiscountsStr) : null
			const discount = appliedSpecialDiscounts ? JSON.parse(appliedSpecialDiscounts?.code) : null
			if (discount?.code === item.code) {
				sessionStorage.removeItem('appliedSpecialDiscounts')
			}
			sendCustomEvent({
				category: 'remove_coupon',
				action: 'clicked',
				coupon_code: item.code,
				coupon_name: item.coupon?.title[Infra?.locale?.msg],
				price: item.coupon.price,
				order_type: [...(item.coupon?.orderTypes || [])],
				offer_type: item.coupon?.offerType || '',
				page_path: window.location.pathname,
			})
		}

		return (
			<Collapse in={isCouponApplied}>
				<Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
					<AccordionSummary expandIcon={<LeftIcon />} data-testid="accordion-summary-container">
						<Title variant="h4" data-testid="coupons-applied-title">
							{getTranslatedTextByKey('eCommerce.cart.appliedCoupons', 'Applied Coupons')}
						</Title>
					</AccordionSummary>

					<AccordionDetails>
						{list.map((item, index) => (
							<React.Fragment key={item.code}>
								<Coupon status={item.status} onUnapply={() => handleUnapply(item)}>
									<CouponItemRow
										status={item.status}
										amount={item.amount}
										amountLeftToGetDiscount={item.amountLeftToGetDiscount}
										coupon={item.coupon}
										locale={item.locale}
										maxOrderPrice={item.maxOrderPrice ?? ''}
									/>
								</Coupon>
								{index !== list.length - 1 && <Divider />}
							</React.Fragment>
						))}
					</AccordionDetails>
				</Accordion>
			</Collapse>
		)
	})
)

export default CouponsList
