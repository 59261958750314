import React, { useRef, useEffect, forwardRef, ForwardedRef, useImperativeHandle } from 'react'
import { inject, observer } from 'mobx-react'
import {WidgetHandle, StartMode} from "@friendlycaptcha/sdk"

import type { InfraProps } from 'mobx/Infra/Infra.type'

const FriendlyCaptcha = inject(
	'Account',
	'Infra'
)(
	observer(forwardRef(({ Infra, onComplete, onError, startMode = 'none' }: { Infra: InfraProps; onComplete: (solution: string) => void; onError: (error: any) => void; startMode: StartMode }, widgetRef: ForwardedRef<{start: () => void}>) => {
		
		const widgetv2 = useRef<WidgetHandle>();
        const siteKey = Infra.appParams.features.friendlyCaptcha?.siteKey

        useImperativeHandle(widgetRef, () => {
            return {
              start() {
				const captchaState = widgetv2.current?.getState()
				if(widgetv2.current && captchaState === "unactivated") {
                    widgetv2.current?.start();
                }
              },
			  reset() {
				const captchaState = widgetv2.current?.getState()
				if(widgetv2.current && (captchaState === 'completed' || captchaState === 'expired')) {
                    widgetv2.current?.reset();
                }
              },
            };
          }, []);

		useEffect(() => {
			const init = async () => {
				const FriendlyCaptchaSDK = (await import('@friendlycaptcha/sdk')).FriendlyCaptchaSDK

				const sdk = new FriendlyCaptchaSDK()
				const formElement = document.getElementById('friendlyCaptchav2')

				// Create the widget
				if(formElement) {
					widgetv2.current = sdk.createWidget({
						element: formElement,
						sitekey: siteKey,
						startMode
					});

					widgetv2.current.addEventListener("frc:widget.complete", (event) => {
						const detail = event.detail;
						console.log("Widget completed, the response: ", detail.response)
						onComplete(detail.response)
					})
					
					widgetv2.current.addEventListener("frc:widget.error", (event) => {
						const detail = event.detail;
						console.error("Something went wrong in solving the captcha: ", detail.error)
						onError(detail.error)
					})
					
					widgetv2.current.addEventListener("frc:widget.expire", (event) => {
						console.warn("The captcha solution is no longer valid, the user waited too long.")
					})
				}
			}

			init()
		}, [])

		return <div id="friendlyCaptchav2" style={{"textAlign": "initial"}} />
	}))
)

export default FriendlyCaptcha
