import { inject, observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'
import IconComponent from 'themes/IconComponent'

const BurgerIcon = (props: object) => <IconComponent asset="/icons/mobileBurger.svg" {...props} />

const StyledBurgerIcon = styled(BurgerIcon)`
	width: 23px;
	height: 23px;
`
const StyledHamburgerMenuButton = styled.button`
	width: 28px;
	height: 28px;
	margin-right: 16px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: transparent;
	padding: 0;
	cursor: pointer;
`

// eslint-disable-next-line react/no-multi-comp
const HamburgerMenuButton = ({ Infra, id }: { id: string; Infra: { toggleMobileMenu: () => void } }) => (
	<StyledHamburgerMenuButton
		id={id}
		onClick={() => {
			Infra.toggleMobileMenu()
		}}
		data-testid="hamburger-menu-button"
	>
		<StyledBurgerIcon />
	</StyledHamburgerMenuButton>
)

export default inject('Infra')(observer(HamburgerMenuButton))
