// @ts-nocheck
import React, { useState, useEffect, useRef, useContext } from 'react'
import { inject, observer } from 'mobx-react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import BackdropBase from '@material-ui/core/Backdrop'
import Collapse from '@material-ui/core/Collapse'
import { Textfit } from 'react-textfit'

import ScrollableTabs from 'components/menu/menuSideBar/ScrollableTabs'
import { mediaQueriesBreakpoints, respondAbove, breakpoints } from 'styles/mixins'
import NewHeaderLayout from 'components/home/NewHomePageHeader/NewHeaderLayout'
import LocalizedHeader from './headers/LocalizedHeaderBar/LocalizedHeader'
import { getTranslatedTextByKey } from 'utils/utils'
import { Page } from 'utils/constants'
import { StyledPrimeButtonText } from 'components/header/header-styles'
import SubHeader from 'components/header/SubHeader'
import TypographyPro from '../../themes/TypographyPro'
import ButtonBase from 'components/common/ButtonBase'
import { StoreContext } from 'contexts/StoreContext'

const allowedPaths = ['/menu']
const MOBILE_THRESHOLD_SCROLL_SIZE = 100
const DESKTOP_THRESHOLD_SCROLL_SIZE = 200
const PAGES_WITH_STATIC_HEADER = [
	Page.LEGAL,
	Page.MENU_ITEM,
	Page.STATIC_MENU,
	Page.DEALS,
	Page.CONTACT_US,
	Page.STORE_LOCATOR,
	Page.SELECTED_STORE,
	Page.HEADER_FOOTER,
]

const Backdrop = styled(BackdropBase)`
	z-index: 1200;
	background-color: rgba(0, 0, 0, 0.6);
`

const StaticSubHeader = styled(SubHeader)`
	height: 60px;
	margin-top: 0;
`

const StyledSubHeaderContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 40px;
`

const StyledSubHeaderTitle = styled(TypographyPro)`
	color: var(--clear);
	width: auto;
	display: none;

	${respondAbove.sm`
  	    display: initial;
  	`}
`

const StyledButton = styled(ButtonBase)`
	width: 100%;
	overflow: hidden;
	${respondAbove.sm`
		width: auto;
	`}
`

const SubHeaderGroup = inject(
	'User',
	'Infra',
	'Home',
	'Application'
)(
	observer(
		({
			User,
			Home,
			Infra,
			Application,
			showStoreInfo,
			showMenuTabs,
			children,
			page,
			deliveryInfo,
			storeData,
			showAddressBox,
			onStartOrderButtonClick,
		}) => {
			const [subHeaderHeight, setSubHeaderHeight] = useState(0)
			const subHeaderRef = useRef(null)
			const isMobile = !useMediaQuery({ query: mediaQueriesBreakpoints.sm })
			const [show, setShow] = useState(true)
			const { nextJS } = useContext(StoreContext)
			const thresholdSize = isMobile ? MOBILE_THRESHOLD_SCROLL_SIZE : DESKTOP_THRESHOLD_SCROLL_SIZE
			// TODO-SERGEY: please consider to replace the PAGES_WITH_STATIC_HEADER with data from params.json if possible
			const displayStaticHeader = PAGES_WITH_STATIC_HEADER.includes(page) && (!showStoreInfo || !deliveryInfo) && !showAddressBox

			const handleScroll = () => {
				if (window.scrollY <= 0) {
					setShow(true)
				} else if (window.scrollY > thresholdSize) {
					setShow(false)
				}
			}

			useEffect(() => {
				if (
					(Infra?.appParams?.features?.staticPagesKeepSubHeaderOnScroll?.length === 0 ||
						!Infra?.appParams?.features?.staticPagesKeepSubHeaderOnScroll?.includes(page)) &&
					!Home?.backdropEnabled
				) {
					window.addEventListener('scroll', handleScroll)
					return () => {
						window.removeEventListener('scroll', handleScroll)
					}
				}
			}, [])

			useEffect(() => {
				if (subHeaderRef?.current) {
					setSubHeaderHeight(subHeaderRef.current.clientHeight)
				}
			}, [subHeaderRef?.current, Home?.branchesList?.msg, Home.locale])

			const shouldShowOnCurrentPage = () => allowedPaths.some((path) => location.pathname.includes(path))

			const getHeaderOffset = () => {
				const baseOffset = isMobile ? 50 : 70
				return show ? subHeaderHeight + baseOffset : baseOffset
			}

			const onMenuLinkClick = () => {
				if (onStartOrderButtonClick) {
					onStartOrderButtonClick()
				} else {
					nextJS.router.push('/')
				}
			}

			return (
				<div>
					<Collapse in={show}>
						{displayStaticHeader && (
							<StaticSubHeader>
								<StyledSubHeaderContainer>
									<StyledSubHeaderTitle variant="h5" component="p">
										{getTranslatedTextByKey('webviewFlow.letsOrder', `Let's order something delicious`)}
									</StyledSubHeaderTitle>
									<StyledButton
										id={`${Application.page}-page-order-now-button`}
										onClick={onMenuLinkClick}
										data-testid="order-now-button"
									>
										<StyledPrimeButtonText component="div">
											<Textfit min={14} max={16}>
												{getTranslatedTextByKey('webviewFlow.startOrder', 'Start Order')}
											</Textfit>
										</StyledPrimeButtonText>
									</StyledButton>
								</StyledSubHeaderContainer>
							</StaticSubHeader>
						)}
						<div ref={subHeaderRef}>
							{children}
							{showAddressBox && <NewHeaderLayout page={page || window.location?.pathname?.split('/')[1]} />}
							{!showAddressBox && showStoreInfo && deliveryInfo && shouldShowOnCurrentPage() && (
								<LocalizedHeader locale={User.session.locale} storeData={storeData} deliveryInfo={deliveryInfo} isLocalizedBar />
							)}
						</div>
						{showAddressBox && <Backdrop open={Home?.backdropEnabled} id="sub-header-backdrop" />}
					</Collapse>

					{showMenuTabs && <ScrollableTabs headerOffset={getHeaderOffset()} />}
				</div>
			)
		}
	)
)

export default SubHeaderGroup
