import { getTranslatedTextByKey } from 'utils/utils'

import googlelibphonenumber from 'google-libphonenumber'
import isEmail from 'validator/lib/isEmail'
import type { LoginMethods } from 'mobx/Infra/Infra.type'
import { LoginMode } from 'mobx/Infra/Infra.type'
const { PhoneNumberType } = googlelibphonenumber
const phoneUtil = googlelibphonenumber.PhoneNumberUtil.getInstance()

export const isValidEmail = (value: string) => isEmail(value, { require_tld: true })

interface LoginModeTranslations {
	invalidErrorMessage: () => string
	title: () => string
	inputLabel: () => string
}

export const getTranslations = (loginMode: LoginMode): LoginModeTranslations => {
	if (loginMode === LoginMode.PhoneOnly) {
		return {
			invalidErrorMessage: () => getTranslatedTextByKey('eCommerce.signIn.invalid.phoneNumber', 'Please enter a valid phone number'),
			inputLabel: () => getTranslatedTextByKey('eCommerce.signIn.phoneNumber', 'Phone number'),
			title: () => getTranslatedTextByKey('eCommerce.signIn.title.phoneNumber', 'Sign In or Create account with your phone number'),
		}
	}

	if (loginMode === LoginMode.EmailOnly) {
		return {
			invalidErrorMessage: () => getTranslatedTextByKey('eCommerce.signIn.invalid.email', 'Please enter a valid email address'),
			inputLabel: () => getTranslatedTextByKey('eCommerce.signIn.email', 'Email address'),
			title: () => getTranslatedTextByKey('eCommerce.signIn.title.email', 'Sign In or Create account with your email'),
		}
	}

	return {
		invalidErrorMessage: () => getTranslatedTextByKey('eCommerce.signIn.invalid.phoneNumberAndEmail', 'Please enter a valid phone/email'),
		inputLabel: () => getTranslatedTextByKey('eCommerce.signIn.phoneNumberAndEmail', 'Phone number / Email'),
		title: () =>
			getTranslatedTextByKey('eCommerce.signIn.title.phoneNumberAndEmail', 'Sign In or Create account with your phone number or email'),
	}
}

interface ValidateInputParams {
	loginMethods: LoginMethods
	loginMode: LoginMode
	defaultCountry: string
	phoneOrEmail: string
}

export const validatePhone = (value: string, defaultCountry: string) => {
	try {
		const phoneNumber = phoneUtil.parse(value, defaultCountry)

		if (phoneUtil.isValidNumber(phoneNumber)) {
			return true
		}

		const numberType = phoneUtil.getNumberType(phoneNumber)

		if (
			numberType === PhoneNumberType.MOBILE ||
			numberType === PhoneNumberType.FIXED_LINE_OR_MOBILE ||
			(defaultCountry && ['MX', 'AR'].indexOf(defaultCountry) > -1 && numberType === PhoneNumberType.FIXED_LINE)
		) {
			return true
		}
	} catch (e: unknown) {
		return false
	}

	return false
}

export const validateInput = ({ loginMethods, loginMode, defaultCountry, phoneOrEmail }: ValidateInputParams) => {
	const validEmail = isValidEmail(phoneOrEmail)
	const errorMessage = getTranslations(loginMode).invalidErrorMessage()

	if (loginMethods.emailSupported && validEmail) {
		return true
	}

	if (loginMode === LoginMode.EmailOnly) {
		return validEmail ? true : errorMessage
	}

	if (!validatePhone(phoneOrEmail, defaultCountry)) {
		return errorMessage
	}

	return true
}
