import { getLocaleStr, getTranslatedTextByKey } from 'utils/utils'
import TypographyPro from 'themes/TypographyPro'
import React from 'react'
import styled from 'styled-components/macro'
import type { CouponListItem } from 'components/common/coupons/types'

const CouponTitle = styled(TypographyPro)`
	color: var(--fonts);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

const CouponAmount = styled(CouponTitle)`
	color: var(--fonts);
`

type CouponItemRowProps = Omit<CouponListItem, 'code'>
const CouponItemRow = ({ status, amount, amountLeftToGetDiscount, coupon, locale, maxOrderPrice }: CouponItemRowProps) => {
	let content = null
	if (status === 'success') {
		content = (
			<>
				{amount ? <CouponAmount variant="BodySmallHighlighted">{amount}</CouponAmount> : null}
				<CouponTitle data-testid="coupon-name" variant="BodySmall">
					{getLocaleStr(coupon.title, locale)}
				</CouponTitle>
			</>
		)
	} else if (maxOrderPrice) {
		content = (
			<>
				{getTranslatedTextByKey(
					'webviewFlow.coupons.maxAmountDiscountExceeded',
					'This discount applies only to orders under {{amount}}. Please adjust your cart to use',
					{
						amount: maxOrderPrice,
					}
				)}{' '}
				<TypographyPro component="span" variant="BodySmallHighlighted">
					"{getLocaleStr(coupon.title, locale)}"
				</TypographyPro>{' '}
				{getTranslatedTextByKey('webviewFlow.coupons.coupon', 'coupon')}!
			</>
		)
	} else {
		content = (
			<>
				{getTranslatedTextByKey('webviewFlow.coupons.spendAnother', 'Spend another')} {amountLeftToGetDiscount}{' '}
				{getTranslatedTextByKey('webviewFlow.coupons.toActive', 'to activate')}{' '}
				<TypographyPro component="span" variant="BodySmallHighlighted">
					"{getLocaleStr(coupon.title, locale)}"
				</TypographyPro>{' '}
				{getTranslatedTextByKey('webviewFlow.coupons.coupon', 'coupon')}!
			</>
		)
	}
	return content
}

export default CouponItemRow
